.vjs-error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.vjs-error-messages {
  text-align: center;
  margin-bottom: 2px;
  color: white !important;
}

.vjs-error-message {
  margin-bottom: 10px;
  font-size: 24px; /* Adjust this value as needed */
}

.vjs-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vjs-retry-button,
.vjs-go-back-button {
  padding: 10px 20px !important;
  background-color: white !important;
  color: black !important;
  text-decoration: none !important;
  border-radius: 10px !important;
  border: 1px solid white !important;
  font-weight: bold !important;
  cursor: pointer !important;
  margin: 2px !important;
}

.vjs-retry-button:hover,
.vjs-go-back-button:hover {
  background-color: #f0f0f0;
}
