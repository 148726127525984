.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  width: 50%;
}

.icon {
  color: rgb(250, 246, 246);
  position: absolute;
  display: block;
  top: 20%;
  left: 106%;
}
.editOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  width: 50%;
}

.editIcon {
  position: absolute;
  top: 60%;
  left: 75%;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: #070707;
  border-width: 2px;
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: var(--iq-dark);
  box-shadow: none;
  outline: none;
}
body {
  background-color: black;
}

/* .MuiSwitch-track {
  width: 100%;
  height: 100%;
  opacity: 0.38;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  /* background-color: #000; */
