/* src/globalStyles.css */

h1 {
  font-family: Arial, sans-serif;
  font-size: 2.5rem;
  color: #333;
}

h2 {
  font-family: Arial, sans-serif;
  font-size: 2rem;
  color: #555;
}

h3 {
  font-family: Arial, sans-serif;
  font-size: 1.75rem;
  color: #777;
}

h4 {
  font-family: Arial, sans-serif;
  font-size: 1.5rem;
  color: #999;
}

h5 {
  font-family: Arial, sans-serif;
  font-size: 1.25rem;
  color: #bbb;
}

h6 {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  color: #ddd;
}
