#btn {
  background: red;
  height: 40px;
  min-width: 100px;
  border: none;
  border-radius: 10px;
  color: rgb(16, 15, 15);
  font-size: 40px;
  font-family: "Cookie", cursive;
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
}

#btn #circle {
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transition: 500ms;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#btn:hover {
  background: transparent;
  color: white;
}

#btn:hover #circle {
  height: 40px;
  width: 100px;
  left: 0;
  border-radius: 0;
  border-bottom: 2px solid #eee;
}

.customOverlay {
  background: rgba(228, 0, 0, 0.7);
}
.customModal {
  background: #00521a;
  max-width: 10000px;
  width: 100%;
}

/* styles.css */

.glow-on-hover {
  width: auto; /* Adjusted width to fit text */
  height: auto; /* Adjusted height to fit text */
  border: none;
  outline: none;
  color: #111; /* Changed text color to dark */
  background: #fff; /* Changed button background color to white */
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  padding: 10px 20px; /* Adjusted padding for better text alignment */
  font-size: 16px; /* Adjusted font size */
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center align text */
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #000000,
    #ffffff
  ); /* Changed gradient colors to black and white */
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #fff; /* Changed text color on active state to white */
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff; /* Changed button background color on hover */
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
