/* .swal2-title {
  background-color: black;
}
.swal2-html-container {
  background-color: black;
}
.swal2-container {
  background-color: black;
} */
.swal2-popup {
  background-color: rgb(18, 18, 24) !important;
}

.swal-modal {
  background-color: rgb(18, 18, 24) !important;
}
/* .swal-icon--success__hide-corners {
  background-color: rgb(18, 18, 24) !important;
} */
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
}
.sa-success {
  border-radius: 50%;
  border: 4px solid #a5dc86;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
}
.sa-success:after,
.sa-success:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(55deg);
  width: 60px;
}
.sa-success:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-success-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(103, 176, 61, 0.25);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-success-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-success-tip,
.sa-success-long {
  background-color: #dc9786;
  border-radius: 2px;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.sa-success-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
  animation: animateSuccessTip 0.75s;
}
.sa-success-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: animateSuccessLong 0.75s;
}
@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
.hoverOver {
  /* padding: 50px; */

  transition: transform 0.2s; /* Animation */

  width: 20px;

  height: 20px;

  margin: 0 auto;
}

.hoverOver:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.swal-modal {
  background-color: rgb(18, 18, 24) !important;

  color: white !important;
}

.swal-icon--success:after,
.swal-icon--success:before {
  background-color: rgb(18, 18, 24) !important;

  color: white !important;
}

.swal-icon--success__hide-corners {
  background-color: rgb(18, 18, 24) !important;
}

.swal-title {
  color: white !important;
}

.swal-text {
  color: white !important;
}

.customModalDash {
  background-color: rgb(18, 18, 24) !important;
}

.react-responsive-modal-modal {
  width: 800px;
}

#loading.display {
  visibility: visible;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
