/** @format */

.fullscreen-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  transition: opacity 2s ease; /* Smooth transition for opacity */
}

.overlay.hidden {
  opacity: 0; /* Hide the overlay */
}

.content {
  color: white;
  max-width: 50%;
  font-size: 1rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.channel-logo-new {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  height: auto;
}
.title {
  font-weight: bolder;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 52px;
}

.title-new {
  color: white; /* Ensure title remains visible when overlay fades out */
  /* position: absolute; */
  bottom: 20px;
  left: 20px;
  transition: bottom 1s ease; /* Smooth transition for moving title */
  max-width: 70%;
  font-weight: bolder;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 52px;
  white-space: nowrap;
}
.long-description {
  font-size: 18px;
  color: white;
  margin-top: 2px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.overlay.hidden + .content .title-new {
  bottom: calc(
    20px + var(--dvui-spacing-600)
  ); /* Adjust bottom position when overlay is hidden */
}

.content-new {
  position: absolute;
  bottom: -100%; /* Initially off screen */
  transition: bottom 1s ease, opacity 2s ease; /* Combined transitions for smooth effect */
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);
  flex-direction: row;
  padding: 20px;
}
.container-new {
  left: 20px;
}

.overlay.hidden + .content-new {
  bottom: -1px;
}

@media (max-width: 768px) {
  .overlay {
    padding: 10px;
  }

  .content {
    font-size: 0.875rem;
    max-width: 70%;
  }

  .channel-logo-new {
    top: 10px;
    right: 10px;
    width: 80px;
  }
}

@media (max-width: 480px) {
  .overlay {
    padding: 5px;
  }

  .content {
    font-size: 0.75rem;
    max-width: 90%;
  }

  .channel-logo-new {
    top: 5px;
    right: 5px;
    width: 60px;
  }
}
