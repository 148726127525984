.customOverlay {
  background: rgba(15, 15, 16, 0.7);
}
.customModal {
  background: black;
  /* max-width: 70vw; */
  width: 100%;
}

.react-responsive-modal-modal {
  background-color: rgb(20, 20, 20) !important;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  /* width: 800 !important; */
  max-width: 800px;
}
@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

/* .marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 5s linear infinite;
} */

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}