/** @format */

.my-anchor-css-class {
  color: grey;
  cursor: pointer;
}
.episode-tab-container {
  width: 100% !important;
  background-color: rgba(14, 11, 11, 0.8) !important;
  height: 100% !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.episode-tab-container:hover {
  background-image: linear-gradient(325deg, #19212e 0%, #12100e 74%);
}
.css-1aquho2-MuiTabs-indicator {
  background-color: white !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #19212e !important;
  width: 4% !important;
}
