/** @format */

.fullscreen-series-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow: auto !important; */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.btn-secondary {
  background-image: linear-gradient(325deg, #19212e 0%, #12100e 74%);
  border-radius: 10px !important;
  padding: 15px !important;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 300%; /* Adjust the height as needed */
  left: -5%;
  right: auto; /* Ensure the overlay fits to the right side */
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 110%),
    linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%),
    linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%); /* Additional gradient to extend black overlay at the bottom */

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  transition: opacity 2s ease; /* Smooth transition for opacity */
  margin-left: 5%;
}

.video-wrapper {
  position: relative;
  /* Add other necessary styles */
}

.video-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust the height as needed */
  background: linear-gradient(to top, black, transparent);
  z-index: 1;
}

.overlay.hidden {
  opacity: 0; /* Hide the overlay */
}

.content {
  color: white;
  max-width: 50%;
  font-size: 1rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.channel-logo-new {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  height: auto;
}
.title {
  font-weight: bolder;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 52px;
}

.title-new {
  color: white; /* Ensure title remains visible when overlay fades out */
  /* position: absolute; */
  bottom: 20px;
  left: 20px;
  transition: bottom 1s ease; /* Smooth transition for moving title */
  max-width: 70%;
  font-weight: bolder;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 52px;
  white-space: nowrap;
}

.overlay.hidden + .content .title-new {
  bottom: calc(
    20px + var(--dvui-spacing-600)
  ); /* Adjust bottom position when overlay is hidden */
}

.content-new {
  position: absolute;
  left: 20px;
  bottom: 30px;
  transition: bottom 1s ease; /* Smooth transition for moving content */
}

.overlay.hidden + .content-new {
  bottom: 50px; /* Show content when overlay is hidden */
}

@media (max-width: 768px) {
  .overlay {
    padding: 10px;
  }

  .content {
    font-size: 0.875rem;
    max-width: 70%;
  }

  .channel-logo-new {
    top: 10px;
    right: 10px;
    width: 80px;
  }
}

@media (max-width: 480px) {
  .overlay {
    padding: 5px;
  }

  .content {
    font-size: 0.75rem;
    max-width: 90%;
  }

  .channel-logo-new {
    top: 5px;
    right: 5px;
    width: 60px;
  }
}
.dropdown-menu {
  background-color: white !important ;
  --bs-dropdown-link-active-bg: gray !important;
}
.icon-wrapper {
  display: inline-block;
  padding: 10px;
  border-radius: 50%; /* Makes the background rounded */
  background: rgba(128, 128, 128, 0.5); /* Gray, glassy background */
  backdrop-filter: blur(5px); /* Glassy effect */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  cursor: pointer;
}

.icon-wrapper:hover {
  background: rgba(128, 128, 128, 0.7); /* Darker background on hover */
  transform: scale(1.1); /* Slightly enlarge the icon */
}
.icon-wrapper-fav {
  height: 60px;
  width: 60px;
  display: flex;
  padding: 5px; /* Adjust padding as needed */
  border-radius: 50%; /* Makes the background rounded */
  /* background: rgba(128, 128, 128, 0.5);  */
  backdrop-filter: blur(5px); /* Glassy effect */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  align-items: center;
  justify-content: center;
}

.icon-wrapper-fav:hover {
  /* background: rgba(128, 128, 128, 0.7);  */
  transform: scale(1.1); /* Slightly enlarge the icon */
}
.video-wrapper {
  position: relative;
  /* Add other necessary styles */
}

.video-wrapper::after {
  content: "";
  position: absolute;
  bottom: -40px; /* Adjust this value to extend further down */
  left: 0;
  width: 100%;
  height: 80px; /* Adjust the height as needed */
  background: linear-gradient(to top, rgb(0, 0, 0), transparent);
  z-index: 2;
}
.show-more-anchor {
  cursor: pointer; /* Ensures the cursor becomes a pointer on hover */
  color: white; /* Optional: Change the color if needed */
  text-decoration: underline; /* Optional: Underline to indicate it's clickable */
}
