.playlist-container {
  position: relative;
  min-width: 300px;
  min-height: 150px;
  height: 0;
}

.playlist-container {
  flex: 1 1 30%;
}

.vjs-playlist {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
