.customOverlay {
  background: rgb(50, 50, 50, 0.8);
}
.customModal {
  background: rgba(7, 7, 7, 0.9);
  min-width: 65vw;

  /* width: 100%; */
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

.css-czcuoc-TimelineDividers {
  z-index: 1;
}
.css-zykxir-TimelineWrapper {
  z-index: 1;
}
.highlight-svg {
  outline: 2px solid transparent; /* Initially set outline to transparent */
  border-radius: 50%; /* Make the border round */
}

.highlight-svg:hover {
  outline-color: rgb(255, 255, 255); /* Add a blurred border color on hover */
}
.my-anchor-css-class {
  color: grey;
  cursor: pointer;
}
