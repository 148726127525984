/** @format */

.vjs-icons {
  justify-content: center;
  align-items: center;
  flex-direction: row !important; /* Stack items vertically */
  position: absolute;
  color: white;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 10%;
  height: 10%; /* Full height */
  top: 10px;
  left: 10px;
  z-index: 3;
}

.vjs-playerback-button {
  width: 35%;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  background-color: #bdafaf1c;
}

.vjs-playerTitle {
  overflow: hidden !important;
  font-size: 25px !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important; /* Stack items vertically */
  position: absolute;
  color: white;
  top: 0%;
  z-index: 2;
  padding: 0; /* Ensure no padding in the container itself */
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto; /* Enables vertical scrolling */
  width: 100%;
  height: 10%;
  transition: top 0.4s ease !important; /* Smooth transition */
  display: none; /* Initially hidden */
  justify-content: space-around;
  background: linear-gradient(
    to bottom,
    rgb(10, 9, 9),
    rgba(56, 55, 55, 0) 100%
  );
}

.vjs-skip-ad-button {
  background-color: #292828 !important;
  color: #fff;
  border: 2px solid !important;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 85%;
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;
  gap: 5px;
}

.vjs-skip-ad-button {
  display: none; /* Initially hidden */
}

.vjs-posterAd-container {
  background-color: transparent;
  position: absolute;
  bottom: 8%;
  right: 22%;
  height: 13%;
  width: 55%;
  padding: 3px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: left 0.4s ease !important; /* Smooth transition */
  display: flex; /* Ensure flexbox layout */
}

.vjs-posterAd {
  max-height: 100%;
  max-width: 100%;
  border-radius: 0px;
  object-fit: fill;

  margin: auto; /* Center the image within the container */
}

.vjs-posterads-close-button {
  position: absolute;
  top: 0px;
  right: 1%;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2; /* Ensure it is above the poster */
  font-size: x-large !important;
}

/* .vjs-upnext-img {
  max-width: 80% !important; 
  max-height: 80% !important;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background-color: #928c9c1a;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.vjs-skip-ad-button {
  background-color: #292828 !important;
  color: #fff;
  border: 1px solid !important;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 75%; /* 25% above the bottom */
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0; /* Start hidden */
  animation: fadeIn 0.6s ease-in-out forwards; /* Apply the animation */
}
.vjs-marker {
  height: 4px !important;
  background: rgb(244, 220, 4) !important;
  width: 0.5% !important;
  position: absolute !important;
  top: 52% !important;
  padding: 0 !important;
  margin-left: -0.25% !important;
}

@media (max-width: 760px) {
  .vjs-icons {
    justify-content: center;
    align-items: center;
    flex-direction: row !important; /* Stack items vertically */
    position: absolute;
    color: white;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    width: 20%;
    height: 20%; /* Full height */
    top: 10px;
    left: 10px;
    z-index: 3;
  }

  .vjs-playerback-button {
    padding: 5px;
    top: 5% !important;
    left: 5% !important;
    top: 10% !important;
    left: 10% !important;
    cursor: pointer;
  }

  .vjs-overlay-button {
    padding: 10px;
    cursor: pointer;
    /* border-radius: 5px;
    position: absolute;
    top: 10% !important;
    left: 10% !important;
    z-index: 9999;
    display: flex;
    justify-content: flex-start !important; */
  }
}
