/** @format */

.btn-primary:focus {
  background-color: red !important;
  border-color: red !important;
}
.btn-primary.focus,
.btn-primary:focus {
  background-color: red !important;
  border-color: red !important;
}

@media only screen and (max-width: 800px) {
  .resetP {
    width: 95% !important;
  }
}

@media only screen and (max-width: 700px) {
  .newBtn {
    width: 60% !important;
  }
}
