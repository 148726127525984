/** @format */

* {
  margin: 0px;
  padding: 0px;
}
.Paper {
  padding: 20px;
  height: 70vh;
  width: 400px;
  margin: "20px auto";
  margin-left: 500px;
}
.avatar {
  background-color: #4b269b;
}

.btn {
  margin-top: "10px";
  padding: "10px";
}

@media only screen and (max-width: 700px) {
  .pageP {
    width: 95% !important;
    height: 80% !important;
  }
}
