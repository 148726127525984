.cardContainer:hover {
  background: hsla(351, 100%, 25%, 1);

  background: linear-gradient(
    90deg,
    hsla(351, 100%, 25%, 1) 0%,
    hsla(9, 59%, 7%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(351, 100%, 25%, 1) 0%,
    hsla(9, 59%, 7%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(351, 100%, 25%, 1) 0%,
    hsla(9, 59%, 7%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#7F0012", endColorstr="#1B0A07", GradientType=1 );
}
.cardContainer {
  border: "2px solid white";
}
