.plan-details-container {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacer {
  height: 80px; /* Spacing between header and content */
}

.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.card-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000; /* Black text color */
  font-weight: bold; /* Bold font weight */
}

.table-wrapper {
  border-radius: 10px; /* Curved edge border */
  overflow: hidden; /* Hide overflow from curved edges */
  border: 2px solid black; /* Black border for the table wrapper */
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff; /* White background for the table */
}

.table th,
.table td {
  padding: 15px;
  border-bottom: 1px solid #eee; /* Light gray border for table cells */
  border-right: 1px solid #eee; /* Light gray border for table cells */
}

.table th {
  background-color: #f2f2f2; /* Light gray background for table header */
  color: #333; /* Dark text color for table header */
}

.red-text {
  color: red; /* Red text color for expiring soon */
}

.table tr:hover {
  background: linear-gradient(
    to right,
    red,
    black,
    white
  ); /* Gradient background on hover for entire row */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.table tr:hover td {
  border: none; /* Remove borders between td when hovering */
}

.table tr:hover td {
  color: white; /* Change text color to white when hovering */
}
.plan-card {
  border: 1px solid #ccc; /* Add border */
  border-radius: 8px; /* Add border radius */
  transition: all 0.3s ease; /* Add transition for smooth hover effect */
}

.plan-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow on hover */
  transform: translateY(
    -4px
  ); /* Add translation on hover for elevation effect */
}
