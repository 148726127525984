.customOverlay {
  background: rgb(50, 50, 50, 0.8);
}
.ModalClassss {
  background: rgb(24, 23, 23);
  min-width: 500px;
  /* width: 600px; */
  /* max-width: 600px;
  min-height: 600px; */
  /* height: 200px; */
  /* width: 500px; */
  /* width: 100%; */
}
