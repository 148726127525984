/* Style for Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: white; /* Arrow color */
  font-size: 24px; /* Increase size */
  font-weight: bold; /* Make it bold */
  background: rgba(0, 0, 0, 0.5); /* Background color for buttons */
  border-radius: 50%; /* Rounded buttons */
  width: 40px; /* Width of buttons */
  height: 40px; /* Height of buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10; /* Ensure buttons are on top */
}

/* Position buttons in the middle of the slider */
.swiper-button-next {
  right: 8px; /* Adjust position for right arrow */

  top: 65%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect centering */
}

.swiper-button-prev {
  left: 8px; /* Adjust position for left arrow */

  top: 65%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect centering */
}
/* Move buttons slightly higher when selectedCategory is 1 */
.category-1 .swiper-button-next,
.category-1 .swiper-button-prev {
  top: 50%; /* Adjust to move the buttons up */
}

/* Optional: Change arrow icons if needed */
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px; /* Size of the arrow icon */
  color: white; /* Arrow icon color */
}

/* Optional: On hover */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(255, 255, 255, 0.7); /* Hover effect */
}

/* Optional: Customize Swiper navigation button for better visibility */

.swiper-slide {
  display: flex;
  justify-content: center; /* Center the content */
  align-items: center; /* Align items in the center */
  position: relative; /* Ensure positioning context */
  overflow: hidden; /* Hide overflow */
}

/* Optional: If you want to limit height and maintain aspect ratio */
.swiper-slide {
  flex: 0 0 auto;
  width: auto; /* Ensure slides can adjust size dynamically */
  height: auto;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease; /* Smooth expansion */
  overflow: hidden; /* Prevent overflow during scaling */
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth transition for the image */
}
/* Container for Swiper slides */
.swiper-container {
  width: 100%; /* Full width */
  padding: 20px; /* Adjust padding if necessary */
}

/* Hover effect to expand both the box and image */
.swiper-slide:hover {
  transform: scale(1.05); /* Slightly enlarge the Swiper slide */
}

.swiper-slide:hover img {
  transform: scale(1.05); /* Smooth image expansion without squeezing */
}
