/** @format */

.nav-pills .nav-link.active {
  color: red;
  /* background-color: red !important; */
  background: linear-gradient(to right, #8d2111, #dd1313, #ca120fe0) !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: rgb(197, 7, 7) !important;
}
.newBtn {
  background: linear-gradient(to right, #8d2111, #dd1313, #ca120fe0) !important;
}
.css-1a5icme.Mui-checked {
  color: rgb(197, 7, 7) !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="date"] {
  --webkit-calendar-picker-indicator-color: red !important;
  border: 1px solid #ccc !important;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
}
