/* Create a simple white box, and add the shadow for the initial state */
.box {
  position: relative;
  display: inline-block;
  width: 20% !important;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(to right, #220703, #b90909, #8b0a08e0);
  box-shadow: 0 1px 2px rgb(225, 78, 58);
  transition: all 0.3s ease-in-out;
  margin-right: 30px;
  border: 1px solid rgb(86, 4, 4) !important;
}
@media only screen and (max-width: 700px) {
  .box {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .hideClass {
    display: none !important;
  }
}

/* Create the hidden pseudo-element */
/* include the shadow for the end state */

/* The fast way */

/* Pre-render the bigger shadow, but hide it */

/* Transition to showing the bigger shadow on hover */

/* Scale up the box */

/* Fade in the pseudo-element with the bigger shadow */

.containerr1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.center {
  flex: none;
  max-width: 100%;
}

.box1 {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

/* The fast way */

.make-it-fast {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

/* Pre-render the bigger shadow, but hide it */

.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */

/* Scale up the box */
.box1 {
  transform: scale(1.2, 1.2);
}

/* Fade in the pseudo-element with the bigger shadow */
.box1 {
  opacity: 1;
}

.offset-bottom-right-shadow {
  position: relative;
  display: inline-block;
  width: 25%;
  height: 60px;
  border-radius: 5px;
  background: linear-gradient(to right, #220703, #b90909, #cf0404);
  box-shadow: 0 1px 2px rgb(225, 78, 58);
  transition: all 0.3s ease-in-out;
  margin-right: 30px;
  border: 1px solid rgb(86, 4, 4) !important;
}
.btnButton {
  border-radius: 10px !important;
  border-radius: 5px;
  background: linear-gradient(to right, #220703, #b90909, #cf0404);
  box-shadow: 0 1px 2px rgb(225, 78, 58);
  border: 1px solid rgb(86, 4, 4) !important;
  padding: 10px;
  width: 60%;
  color: white;
}
.my-anchor-css-class {
  color: grey;
  cursor: pointer;
}
