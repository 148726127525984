/** @format */

.containerNew {
  margin: 0 !important;
  padding: auto !important;
}
/* .vjs-forward-control vjs-control vjs-button {
  background-color: red !important;
} */

.vjs-replay-button {
  position: absolute !important;
  top: 58% !important;
  left: 53% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10 !important; /* Ensure it is above other elements */
  font-size: 2em !important; /* Increase the size if necessary */
}
.vjs-big-play-button {
  position: absolute !important;
  top: 58% !important;
  left: 53% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10 !important; /* Ensure it is above other elements */
  font-size: 2em !important; /* Increase the size if necessary */
}
.vjs-info {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  /* background-color: blue !important; */
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  position: fixed !important;
}
.vjs-info .vjs-text {
  padding: 0% !important;
  margin: 0% !important;
  /* left: 40 !important; */
  /* background-color: red; */
  margin-right: 21% !important;
  width: 600px !important;
}
/* .vjs-rewind-control .vjs-icon-placeholder {
  padding: 0% !important;
  content: url("/public/images/icons/backward.png");
  margin: 0% !important;
}

.vjs-forward-control .vjs-icon-placeholder {
  content: url("/public/images/icons/forward.png");
}
.vjs-rewind-control .num {
  display: none;
}
.vjs-forward-control .num {
  display: none;
} */
.vjs-rewind-control::before {
  content: "Rewind"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 70%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.vjs-rewind-control:hover::before {
  opacity: 1;
}

.vjs-forward-control::before {
  content: "Forward"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.vjs-forward-control:hover::before {
  opacity: 1;
}

.vjs-paused::before {
  content: "Play"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.vjs-paused:hover::before {
  opacity: 1;
}

.vjs-paused::before {
  content: "Play"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.vjs-paused:hover::before {
  opacity: 1;
}
.vjs-playing::before {
  content: "Pause"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
}
.vjs-playing:hover::before {
  opacity: 1;
}

.vjs-picture-in-picture-control::before {
  content: "Picture-in-Picture"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 10%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
  white-space: nowrap;
}
.vjs-picture-in-picture-control:hover::before {
  opacity: 1;
}

.vjs-fullscreen-control::before {
  content: "Fullscreen"; /* Tooltip text */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Translucent white background */
  backdrop-filter: blur(4px); /* Blur effect */
  color: white; /* Bright white color */
  font-weight: 300; /* Thin font weight */
  font-size: 14px;
  padding: 8px 12px; /* Increased padding for better readability */
  border-radius: 4px;
  bottom: calc(100% + 8px); /* Position slightly above the button */
  left: 10%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1;
  white-space: nowrap;
}
.vjs-fullscreen-control:hover::before {
  opacity: 1;
}

/* VideoPlayer.css */
.video-js .vjs-control .vjs-volume-panel-horizontal .vjs-slider-active {
  background-color: rgb(255, 0, 149);

  color: rgb(49, 223, 14);
}

/* vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal vjs-slider-active" */
/* vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal */
.vjs-play-control {
  display: none !important;
}

/* volumePanel: {
  inline: false, // Horizontal volume control
}, */

/* .vjs-volume-control .vjs-control .vjs-volume-vertical .vjs-hover {
  background-color: rgb(255, 0, 149);
  margin-left: 10px;
  color: rgb(49, 223, 14);
} */

/* class="vjs-volume-control vjs-control vjs-volume-vertical" */
.video-js .vjs-volume-panel .vjs-volume-control {
  margin-left: 10px;
}
