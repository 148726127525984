#btn {
  background: red;
  height: 40px;
  min-width: 100px;
  border: none;
  border-radius: 10px;
  color: rgb(16, 15, 15);
  font-size: 40px;
  font-family: "Cookie", cursive;
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
}

#btn #circle {
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transition: 500ms;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#btn:hover {
  background: transparent;
  color: white;
}

#btn:hover #circle {
  height: 40px;
  width: 100px;
  left: 0;
  border-radius: 0;
  border-bottom: 2px solid #eee;
}
.modal-content {
  background-color: rgba(7, 7, 7, 0.9) !important;
}
.modal-header {
  border-bottom: black !important;
}
.modal-90w {
  width: 70% !important;
  max-width: none !important;
}
.btn-close {
  color: white !important;
}
