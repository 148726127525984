/**
 * Copyright 2014 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
::-moz-focus-inner,:active,:focus{
	outline:none;
	border:0;
	-moz-outline-style: none;
}
.ima-ad-container {
  top: 0px;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
}
.ima-ad-container > div:first-of-type{
   width:100%!important;
   height:100%!important;
}

.video-js  .bumpable-ima-ad-container {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

/* Move overlay if user fast-clicks play button. */
.video-js.vjs-playing .bumpable-ima-ad-container {
  margin-top: 0;
}

/* Move overlay when controls are active. */
.video-js.vjs-user-inactive.vjs-playing .bumpable-ima-ad-container {
  margin-top: 0;
}

.video-js.vjs-paused .bumpable-ima-ad-container,
.video-js.vjs-playing:hover .bumpable-ima-ad-container,
.video-js.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
  margin-top: -40px;
}



.ima-controls-div-showing {
    opacity:1!important
}
.ima-controls-div,
.ima-seek-bar-div {
    bottom: 0;
    width: 100%;
    position: absolute
}
.ima-controls-div {
    background: linear-gradient(transparent,rgba(0,0,0,.5));
    height: 38px;
    left: 0;
    right: 0;
    text-align: right;
    padding: 0;
    opacity: 1;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
    display: none;
    z-index: 97
}

.ima-countdown-div {
	line-height: 38px;
    padding: 0 10px;
    float: left;
    text-shadow: 1px 1px 1px #000;
	color:#fff;
	font-size:12px;
}

.ima-seek-bar-div {
	position:absolute;
	height:4px;
	bottom:0;
	width:100%;

	margin:0;
	background-color:transparent;
	max-width:100%;
}
.ima-seek-bar-div:before {
    background-color: rgba(255,255,255,0.3);
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
	border:0;
	cursor:pointer;
}

.ima-progress-div {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
	width:0;
	background:#FFCC00;
}



.ima-play-pause-div, .ima-mute-div, .ima-non-mute-div, .ima-fullscreen-div, .ima-non-fullscreen-div {
	position: relative;
    display: inline-block;
    vertical-align: top;
    line-height: 33px;
	font-family:nuevo;
	text-align:center;
	padding:0;
	color:#fff;
	text-shadow:1px 1px 1px #000;
	display:block!important;
	cursor:pointer;
	width:40px;
}


.ima-play-pause-div {
	float:left;
	font-size:21px;
	left:2px;
}
.ima-play-pause-div:bfore {
	 content: "\e902";
	 font-size:20px;
	 left:5px;
}

.ima-slider-div {
  height: 4px;
  width:80px;
  float:left;
  background-color: #555555;
  margin-right:3px;
  margin-top:16px;
  cursor:pointer;
}

.ima-slider-level-div {
  width: 100%;
  height: 100%;
  background-color: #FFCC00;
}


.ima-fullscreen, .ima-non-fullscreen {
	float:right;
	font-size:16px;
	right:5px;
}
.ima-muted, .ima-non-muted {
	font-size:20px;
	float:left;
}
.ima-playing:before {
  content: "\f103";
}

.ima-paused:before {
  content: "\f101";
}

.ima-non-muted:before {
    content: "\f107";
    font-family: nuevo;
    
}

.ima-muted:before {
	content: "\f104";
    font-family: nuevo;

}

.ima-non-fullscreen:before {
  content:"\f065";
}

.ima-fullscreen:before {
  content:"\f066";
}