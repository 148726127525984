.react-confirm-alert-body {
  background-color: black;
  height: 200px;
}
.react-confirm-alert-button-group > button {
  width: 25%;
  height: 20%;
}
.react-confirm-alert-body > h1 {
  font-size: 20px;
}
.swal-text {
  font-size: 20px;
  color: black;
  font-weight: bold;
}
.swal-button {
  background-color: #3f51b5;
}
