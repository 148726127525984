/** @format */

@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.search-form .input-group {
  float: right !important;
  transition: all 0.35s, border-radius 0s;
  width: 35px;
  height: 35px;
  background-color: #000000;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 25px;
  border: 1px solid #1b0000;
}
.search-form .input-group input.form-control {
  margin-left: -25px;
  margin-top: -7px;
  border: none;
  background: transparent;
  box-shadow: none;
  display: block;
  padding: 1rem 2rem;
  color: rgb(255, 255, 255);
}

.search-form .input-group input.form-control::-webkit-input-placeholder {
  display: none;
}
.search-form .input-group input.form-control:-moz-placeholder {
  /* Firefox 18- */
  display: none;
}
.search-form .input-group input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
}
.search-form .input-group input.form-control:-ms-input-placeholder {
  display: none;
}
.search-form .input-group:hover,
.search-form .input-group.hover {
  width: 100%;
  border-radius: 25px 25px 25px 25px;
}

.search-form .input-group span.form-control-feedback {
  position: absolute;
  top: -1px;
  bottom: 0px;
  right: -2px;
  left: 5px;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  left: initial;
  font-size: 14px;
}
/* Search End Here */
.input {
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: #0d1117;
  justify-content: center;
  border-radius: 5px;
}

.value {
  background-color: transparent;
  border: none;
  padding: 10px;
  color: white;
  display: flex;
  position: relative;
  gap: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.value:not(:active):hover,
.value:focus {
  background-color: #21262c;
}

.value:focus,
.value:active {
  background-color: #1a1f24;
  outline: none;
}

.value::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -10px;
  width: 5px;
  height: 80%;
  background-color: #2f81f7;
  border-radius: 5px;
  opacity: 0;
}

.value:focus::before,
.value:active::before {
  opacity: 1;
}

.value svg {
  width: 15px;
}

.input:hover > :not(.value:hover) {
  transition: 300ms;
  filter: blur(1px);
  transform: scale(0.95, 0.95);
}

.iiii-ioio {
  position: relative !important;

  display: flex !important;

  align-items: center !important;
}

.iiii {
  width: 20px !important;

  height: 5px !important;

  border-radius: 20px !important;

  border: none !important;

  outline: none !important;

  padding: 18px 16px !important;

  background-color: transparent !important;

  cursor: pointer !important;

  transition: all 0.5s ease-in-out !important;
}

.iiii::placeholder {
  color: transparent !important;
}

.iiii:focus::placeholder {
  color: rgb(255, 255, 255) !important;
}

.iiii:focus,
.iiii:not(:placeholder-shown) {
  background-color: #000000 !important;

  border: 1px solid rgb(255, 255, 255) !important;

  width: 290px !important;

  cursor: progress !important;

  padding: 18px 16px 18px 45px !important;
}

.popo {
  position: absolute !important;

  left: 0 !important;

  height: 34px !important;

  width: 34px !important;

  background-color: #000000 !important;

  border-radius: 99px !important;

  z-index: -1 !important;

  fill: rgb(2, 2, 7) !important;

  /* border: 1px solid rgb(255, 255, 255) !important; */
}

.iiii:focus + .popo,
.iiii:not(:placeholder-shown) + .popo {
  z-index: 0 !important;

  background-color: transparent !important;

  border: none !important;
}

.swal-text {
  text-align: center !important;
}
