/** @format */

.activeTab1 {
  border-bottom: 4px solid red;
  width: 110px;
}

.activeTab2 {
  border-bottom: 4px solid red;
  width: 110px;
}

.activeTab3 {
  border-bottom: 4px solid red;
  width: 110px;
}

.activeTab4 {
  border-bottom: 4px solid red;
  width: 130px;
}
.text-primary {
  color: red;
}
.swal2-icon {
  height: 4rem !important;
  width: 4rem !important;
}
