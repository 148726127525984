.carousel-control-prev {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: rgb(11, 11, 11);
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  visibility: visible;
}
@media (max-width: 900px) {
  .carousel {
    width: auto;
    height: auto;
  }
}

.my-anchor-css-class {
  color: grey;
  cursor: pointer;
}
