.fullscreen-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.background-image {
  width: 110%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  width: 100vw; /* Ensures the overlay covers the entire viewport width */
  height: 120vh; /* Extends the height of the overlay beyond the screen */
  left: -5%;
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%),
    /* Right-side gradient */
      linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0) 30%),
    /* Left-side gradient */
      linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%); /* Extended top gradient */

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  transition: opacity 2s ease;
  margin-left: 5%;
  bottom: -30px; /* Allows further extension of the overlay */
}

.video-wrapper {
  position: relative;
}

.video-wrapper::after {
  content: "";
  position: absolute;
  bottom: -60px; /* Extends more downwards */
  left: 10;
  width: 100%;
  height: 100px; /* Adjusted height to extend black overlay */
  background: linear-gradient(to top, rgb(0, 0, 0), transparent);
  z-index: 2;
}

.overlay.hidden {
  opacity: 0;
}

.content {
  color: white;
  max-width: 50%;
  font-size: 1rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.channel-logo-new {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  height: auto;
}

.MuiTabs-scroller {
  z-index: 90000; /* Adjust this value as needed */
}

/* Responsive styles remain unchanged */
