/** @format */

.SelectedStyle {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(135deg, #ed1c24 0%, #760405 99.99%, #750405 100%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.input-single1 input:focus,
.input-single1 input:hover {
  border: 2px solid #ed1c24;
}
.activePlat {
  /* background: linear-gradient(180deg, #ed1c24 0%, rgba(237, 28, 36, 0) 100%); */
  border: 3px solid red; /* Initially set the border to transparent */
  box-shadow: 0 0 10px rgba(238, 26, 26, 0.5); /* Add a shadow with red color and some transparency */
  opacity: 1;
  transition: border 0.3s ease; /* Add a transition for smoother effect */
  border-radius: 12px;
}
/* .login-slider1 .slick-current .SelectedStyle {
  opacity: 1;
  visibility: visible;
} */
